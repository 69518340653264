import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Slide, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const DigitalTimer = ({ startTime, endTime, onTimeUp }) => {
  const [secondsLeft, setSecondsLeft] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [animationStyle, setAnimationStyle] = useState({});

  useEffect(() => {
    const end = new Date(endTime);

    const timerId = setInterval(() => {
      const now = new Date();

      if (now >= end) {
        clearInterval(timerId); // Clear the interval if time's up
        onTimeUp();
      } else {
        const start = new Date(startTime);
        const totalTime = Math.round((end - start) / 1000);
        const elapsed = Math.round((now - start) / 1000);
        const secondsLeft = Math.max(totalTime - elapsed, 0);
        setSecondsLeft(secondsLeft);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [startTime, endTime, onTimeUp]);

  // Update the animation style when we enter warning thresholds.
  useEffect(() => {
    if (secondsLeft !== null && secondsLeft <= 60) {
      if (secondsLeft <= 10) {
        // When 10 seconds or less remain, use a rapid pulse.
        setAnimationStyle({
          animation: 'pulseRapid 0.5s ease-in-out infinite',
        });
      } else {
        // When between 11 and 60 seconds, use a slower pulse.
        setAnimationStyle({ animation: 'pulse 1s ease-in-out infinite' });
      }
    } else {
      setAnimationStyle({});
    }
  }, [secondsLeft]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  if (secondsLeft === null) {
    return null;
  }

  return (
    <>
      <style>
        {`
          @keyframes pulse {
            0% { color: #434F79; }
            50% { color: #FFA500; }
            100% { color: #434F79; }
          }
          @keyframes pulseRapid {
            0% { color: #434F79; }
            50% { color: #FF0000; }
            100% { color: #434F79; }
          }
        `}
      </style>
      <Slide
        direction="up"
        in={secondsLeft !== null}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            width: isMobile ? '80%' : '90%',
            padding: isMobile ? 1 : 2,
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
            borderRadius: '15px',
            background: 'linear-gradient(145deg, #e6e8ec, #ffffff)',
            zIndex: 2,
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton
                onClick={() => setIsVisible(!isVisible)}
                sx={{
                  fontSize: isMobile ? '1.5rem' : '2rem',
                  color: 'rgb(67, 87, 117)',
                  '&:hover': {
                    backgroundColor: 'rgba(67, 87, 117, 0.1)',
                  },
                }}
              >
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                variant={isMobile ? 'subtitle1' : 'h5'}
                align="center"
                sx={{
                  visibility: isVisible ? 'visible' : 'hidden',
                  opacity: isVisible ? 1 : 0,
                  transition: 'opacity 0.3s, visibility 0.3s',
                  fontFamily: 'Digital-7 Mono, sans-serif',
                  ...animationStyle,
                  fontWeight: 'bold',
                }}
              >
                {formatTime(secondsLeft)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Slide>
    </>
  );
};

export default DigitalTimer;
