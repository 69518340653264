import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import UserProfile from './UserProfile';
import RecentExams from './RecentExams';
import { motion } from 'framer-motion';
import ExamResultsChart from './ExamResultsChart';
import { useAuth } from '../../Contexts/AuthContext';
import BlurredGraph from './UpgradeSamples/BlurredGraph';
import SubscriptionDetails from './SubscriptionDetails';
import { renderProgress } from '../../Utilities/RenderProgress';
import { usePersonalData } from '../../Hooks/usePersonalData';
import RecentExamsSkeleton from '../../loaders/RecentExamsSkeleton';
import { Helmet } from 'react-helmet-async';

const PersonalArea = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useAuth();
  const showSubscription =
    user?.role === 'ADMIN' ||
    user?.role === 'FREEMIUM' ||
    user?.role === 'EXPERIMENTAL';

  const showSubscriptionDetails =
    user?.role !== 'ADMIN' &&
    user?.role !== 'USER' &&
    user?.role !== 'FREEMIUM' &&
    user?.role !== 'EXPERIMENTAL';

  const { loading, recentExamsScores, subscription, perks, error } =
    usePersonalData(user.id, showSubscription);
  const isExamTimeOver = (endTime) => {
    const currentTimestamp = new Date().getTime();
    const examEndTimestamp = new Date(endTime).getTime();

    return currentTimestamp > examEndTimestamp;
  };

  const navigateToExam = (examId) => {
    navigate(`/exam/initiate/${examId}`);
  };

  const renderRecentExams = () => {
    if (loading.recentExamsScores) return <RecentExamsSkeleton count={3} />;
    if (error.recentExamsScores) return `Error: ${error.recentExamsScores}`;
    if (recentExamsScores === null) return <BlurredGraph />;
    return (
      <RecentExams
        recentExamsScores={recentExamsScores}
        isExamTimeOver={isExamTimeOver}
        navigateToExam={navigateToExam}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title> المساحة الشخصية - אל-יע״ל</title>
      </Helmet>
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{
              mt: 3,
              mb: 1,
            }}
          >
            المنطقة الشخصية
          </Typography>

          <Grid container>
            {/* User Information */}
            <Grid item xs={12} md={4}>
              <UserProfile
                userInfo={user}
                studyConsistency={0}
                renderProgress={renderProgress}
              />
            </Grid>

            <Grid item xs={12} md={8} container direction="column" spacing={2}>
              {/* Exam Results Chart */}
              <Grid
                item
                xs
                style={{
                  maxWidth: '100%',
                  maxHeight: isMobile ? '360px' : '360px',
                }}
              >
                <ExamResultsChart />
              </Grid>

              {/* Recent Exams */}
              <Grid
                item
                xs
                style={{
                  maxWidth: '100%',
                  maxHeight: isMobile ? '270px' : '260px',
                }}
              >
                {renderRecentExams()}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={8}>
              {showSubscriptionDetails && (
                <SubscriptionDetails
                  subscription={subscription}
                  perks={perks}
                />
              )}
            </Grid>
          </Grid>
          <Divider />
        </motion.div>
      </Container>
    </>
  );
};

export default memo(PersonalArea);
