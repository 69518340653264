import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  DialogTitle,
  Button,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { StyledTextarea } from '../../DataManagement/Inputs/StyledTextarea';
import { updateComposition } from '../../../../Services/CompositionService';
import { Colors } from '../../../../Utilities/Colors';
import Lottie from 'lottie-react';
import hourglass from '../../../../loaders/hourglass.json';

const CompositionDialog = ({ composition, onClose, fetchCompositions }) => {
  const [grade, setGrade] = useState(composition?.grade || '');
  const [notes, setNotes] = useState(composition?.teacherNotes || '');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setGrade(composition?.grade || '');
    setNotes(composition?.teacherNotes || '');
  }, [composition]);

  const handleUpdateComposition = async () => {
    setIsLoading(true);
    try {
      await updateComposition(composition.id, grade, notes);
      onClose();
      fetchCompositions();
    } catch (error) {
      console.error('Error saving changes:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (!composition) return null;

  return (
    <Dialog
      open={Boolean(composition)}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: Colors.platinum,
        },
      }}
    >
      <DialogTitle align="center" style={{ color: Colors.darkBlue }}>
        مراجعة الإنشاء
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box
              sx={{
                p: 3,
                backgroundColor: Colors.inputColor,
                borderRadius: 3,
                border: `1px solid ${Colors.lightBlue}`,
                minHeight: '400px',
                overflowY: 'auto',
              }}
            >
              <Typography
                variant="h5"
                align="center"
                gutterBottom
                sx={{
                  color: Colors.darkBlue,
                  borderBottom: `2px solid ${Colors.lightBlue}`,
                  pb: 1,
                  mb: 2,
                }}
              >
                محتوى الإنشاء
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: Colors.independence,
                  lineHeight: 1.6,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {composition.textContent}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box position="sticky" top="5px">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    label="العلامة"
                    type="number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={grade}
                    onChange={(e) => {
                      const newValue = Math.max(
                        1,
                        Math.min(150, parseInt(e.target.value, 10) || 0)
                      );
                      setGrade(newValue);
                    }}
                    style={{
                      backgroundColor: Colors.inputColor,
                      color: Colors.darkBlue,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleUpdateComposition}
                    disabled={isLoading}
                    sx={{
                      mt: 2,
                      height: '40px',
                      backgroundColor: '#435775',
                      color: '#EAEAEC',
                      transition: 'transform 0.3s, boxShadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#2B5AAA',
                      },
                    }}
                  >
                    {isLoading ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                      >
                        <Grid item xs={4}>
                          <Lottie
                            animationData={hourglass}
                            style={{
                              width: '40px',
                              height: '40px',
                            }}
                            loop
                            autoplay
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="subtitle2">
                            جاري الحفظ...
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      'حفظ التغييرات'
                    )}
                  </Button>
                </Grid>
              </Grid>
              <StyledTextarea
                id="Teacher Notes"
                name="Teacher Notes"
                minRows={12}
                maxRows={14}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="أدخل الملاحظات هنا"
                aria-label="أدخل الملاحظات هنا"
                style={{
                  backgroundColor: Colors.inputColor,
                  borderColor: Colors.mediumBlue,
                  color: Colors.darkBlue,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CompositionDialog;
