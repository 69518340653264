import React, { useState } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import useIsAdmin from '../../Hooks/useIsAdmin';

import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import { motion } from 'framer-motion';
import { Colors } from '../../Utilities/Colors';
import { Link } from 'react-router-dom';

const NavBar = () => {
  //const { isSubscriber } = useIsSubscriber();

  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, logout, isLoggingOut } = useAuth();
  const isAdmin = useIsAdmin();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = (state) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(state);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* {!isSubscriber && (
        <div
          style={{
            backgroundColor: '#f4f4f4',
            height: '64px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 10px',
            maxWidth: 1125,
            width: '100%',
            margin: '0 auto',
          }}
        >
          <img
            src="/assets/yael-logo-imp.png"
            alt="Logo"
            style={{
              height: '100%',
              maxHeight: '56px',
              width: 'auto',
            }}
          />
          <Button
            component={Link}
            to="/subscriptions/plans"
            variant="contained"
            sx={{
              textTransform: 'none',
              borderColor: '#000',
              color: '#ffffff',
              fontFamily: 'ExpoArabic',
              backgroundColor: '#e37c2b',
              padding: '0.3rem 2rem',
              '&:hover': {
                backgroundColor: '#c66a25',
                borderColor: '#000',
                '@media (hover: none)': {
                  backgroundColor: '#e37c2b',
                },
              },
              transition: 'background-color 0.3s ease',
            }}
          >
            اشترك الآن
          </Button>
        </div>
      )} */}

      <AppBar
        position="static"
        sx={{
          backgroundColor: Colors.electricBlue,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            padding: '0 1rem',
            transition: 'all 0.3s',
            maxWidth: 1200,
            width: '100%',
            margin: '0 auto',
          }}
        >
          {isMobileView ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggleDrawer(true)}
                sx={{
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                  transition: 'all 0.3s',
                }}
              >
                {isOpen ? (
                  <CloseIcon sx={{ fontSize: '2rem' }} />
                ) : (
                  <MenuIcon sx={{ fontSize: '2rem' }} />
                )}
              </IconButton>
              <Typography
                component={Link}
                to="/"
                sx={{
                  color: 'inherit',
                  textDecoration: 'none',
                  fontSize: '1rem',
                  flexGrow: 1,
                  textAlign: 'left',
                  fontFamily: 'ExpoArabic',
                }}
              >
                الرئيسية
              </Typography>
              <MobileNavBar
                isOpen={isOpen}
                toggleDrawer={toggleDrawer}
                isLoggingOut={isLoggingOut}
                isAuthenticated={isAuthenticated}
                isAdmin={isAdmin}
                logout={logout}
              />
            </>
          ) : (
            <DesktopNavBar
              isAuthenticated={isAuthenticated}
              logout={logout}
              isAdmin={isAdmin}
              isLoggingOut={isLoggingOut}
            />
          )}
        </Toolbar>
      </AppBar>
    </motion.div>
  );
};

export default NavBar;
