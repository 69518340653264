import React from 'react';
import { Typography, Button, Divider, darken } from '@mui/material';
import OverlayPaper from '../../Utilities/OverlayPaper';
import Lottie from 'lottie-react';
import fetchAnimation from '../../loaders/fetchAnimation.json';
import { timeUtil } from '../../Utilities/timeUtil';

const PracticeTypeCard = ({
  title,
  description,
  buttonLabel,
  onClick,
  perkType,
  userPlan,
  userPerkUsage,
  isAdmin,
  loading,
}) => {
  const perkUsage =
    isAdmin || !userPerkUsage
      ? null
      : userPerkUsage.find((p) => p.perk.perkType === perkType);

  const hasAccessToPerk = isAdmin || !!perkUsage;
  const isAvailable =
    isAdmin ||
    (hasAccessToPerk &&
      (!perkUsage?.usage ||
        perkUsage.usage.quantityUsed < perkUsage.perk.quantity));

  const currentTime = new Date();
  const regenerationTime = perkUsage?.regenerationTime
    ? new Date(perkUsage.regenerationTime)
    : null;

  const nextAvailableTime = regenerationTime
    ? timeUtil.inHoursAndMinutes(currentTime, regenerationTime)
    : null;

  const isOneTimePerk = perkUsage?.perk?.frequency === 'ONE_TIME';
  const isOneTimePerkUsed =
    isOneTimePerk && perkUsage?.usage?.quantityUsed >= 1;
  const usedDate = isOneTimePerkUsed
    ? timeUtil.formatDate(perkUsage.usage.usageTime)
    : null;

  return (
    <OverlayPaper>
      <Typography
        variant="h5"
        component="div"
        sx={{
          textAlign: 'center',
          mb: 2,
          fontFamily: 'ExpoArabic',
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          textAlign: 'center',
          mb: 3,
          color: '#EAEAEC',
          fontFamily: 'ExpoArabic',
        }}
      >
        {description}
      </Typography>

      <Divider sx={{ width: '100%', mb: 2, backgroundColor: '#737474' }} />

      {!isAdmin && hasAccessToPerk && perkUsage?.perk?.quantity && (
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: 'center',
            mb: 3,
            color: '#EAEAEC',
            fontFamily: 'ExpoArabic',
          }}
        >
          {isAvailable &&
            !isOneTimePerkUsed &&
            (() => {
              const remaining =
                perkUsage.perk.quantity - (perkUsage.usage?.quantityUsed || 0);
              // If this is the "unlimited" (HUNDRED_TIMES) perk, only show count if 3 or fewer exams remain.
              if (perkUsage.perk.frequency === 'HUNDRED_TIMES') {
                return remaining <= 3 ? `متبقي: ${remaining}` : 'متاح';
              } else {
                return `متاح: ${remaining}`;
              }
            })()}
          {!isAvailable &&
            !isOneTimePerk &&
            nextAvailableTime &&
            `التالي متاح في ${nextAvailableTime}`}
          {isOneTimePerkUsed && `تم استخدامه في ${usedDate}`}
        </Typography>
      )}
      {!loading && !hasAccessToPerk && (
        <Typography
          variant="body2"
          color="#FD8F24"
          sx={{ mb: 3, fontFamily: 'ExpoArabic' }}
        >
          ليس لديك الوصول إلى هذه الميزة مع خطة الاشتراك الحالية الخاصة بك.
        </Typography>
      )}

      <Button
        variant="contained"
        fullWidth
        onClick={onClick}
        disabled={!isAvailable || loading}
        sx={{
          mb: 3,
          maxWidth: '250px',
          borderRadius: '8px',
          backgroundColor: isAvailable ? '#DADADC' : 'error.main',
          ':hover': {
            backgroundColor: isAvailable
              ? darken('#DADADC', 0.1)
              : 'error.dark',
          },
          color: isAvailable ? '#23314B' : '#DADADC',
          '&.Mui-disabled': {
            color: '#acacac',
          },
          fontFamily: 'ExpoArabic',
        }}
      >
        {loading ? (
          <Lottie
            animationData={fetchAnimation}
            style={{ width: '100px', height: '40px', margin: 'auto' }}
          />
        ) : isAvailable ? (
          buttonLabel
        ) : (
          'غير متاح'
        )}
      </Button>
    </OverlayPaper>
  );
};
export default PracticeTypeCard;
