import { useEffect, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { getUserSubscriptionPlan } from '../Services/UserService';

const useIsSubscriber = () => {
  const { user } = useAuth();
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }
    const checkSubscription = async () => {
      try {
        const subscriptionData = await getUserSubscriptionPlan(user.id);
        if (
          subscriptionData &&
          subscriptionData.subscriptionStatus === 'ACTIVE'
        ) {
          const role = subscriptionData.subscriptionPlan.role;
          // Explicitly treat FREEMIUM as non-subscriber,
          // and EXPERIMENTAL (or any other role) as subscriber.
          switch (role) {
            case 'FREEMIUM':
              setIsSubscriber(false);
              break;
            case 'EXPERIMENTAL':
              setIsSubscriber(true);
              break;
            default:
              setIsSubscriber(true);
          }
        } else {
          setIsSubscriber(false);
        }
      } catch (error) {
        console.error('Failed to get subscription plan', error);
        setIsSubscriber(false);
      } finally {
        setLoading(false);
      }
    };

    checkSubscription();
  }, [user]);

  return { isSubscriber, loading };
};

export default useIsSubscriber;
