import { axiosInstance, apiWrapper } from './Service';

export async function initiateComposition(userId) {
  const response = await apiWrapper(
    () => axiosInstance.post('/compositions/initiate', { userId }),
    'Composition initiated successfully',
    'Failed to initiate composition'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function submitComposition(textContent, userId) {
  const payload = { textContent, userId };

  const response = await apiWrapper(
    () => axiosInstance.post('/compositions/submit', payload),
    'Composition submitted successfully',
    'Failed to submit composition'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getAllCompositions() {
  const response = await apiWrapper(
    () => axiosInstance.get('/compositions'),
    'Compositions retrieved successfully',
    'Failed to fetch compositions'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function updateComposition(compositionId, grade, teacherNotes) {
  const response = await apiWrapper(
    () =>
      axiosInstance.put(`/compositions/${compositionId}`, {
        grade,
        teacherNotes,
      }),
    'Composition changes saved successfully',
    'Failed to save composition changes'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function deleteComposition(compositionId) {
  const response = await apiWrapper(
    () => axiosInstance.delete(`/compositions/${compositionId}`),
    'Composition deleted successfully',
    'Failed to delete composition'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getLastCompositionByUserId(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/compositions/last/${userId}`),
    'Last composition retrieved successfully',
    'Failed to fetch last composition'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
