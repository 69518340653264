import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TextField,
  IconButton,
  Typography,
  Grid,
  Container,
  Chip,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getAllUsers } from '../../../Services/AdminService';
import UserActions from './UserActions';
import { StyledTableCell } from '../DataManagement/Inputs/StyledTableCell';
import { StyledTableRow } from '../DataManagement/Inputs/StyledTableRow';
import gear from '../../../loaders/gear.svg';
import Lottie from 'lottie-react';
import fetchAnimation from '../../../loaders/fetchAnimation.json';

const columnHeaders = [
  { label: 'الاسم الأول', key: 'firstName' },
  { label: 'اسم العائلة', key: 'lastName' },
  { label: 'البريد الإلكتروني', key: 'email' },
  { label: 'رقم الهاتف', key: 'phoneNumber' },
  { label: 'الإجراء', key: 'actions', width: '20%' },
];

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getAllUsers();
      setUsers(fetchedUsers);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    fetchUsers();
  }, []);

  const filteredUsers = users.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Helper function to format Israeli phone numbers
  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    let cleaned = phoneNumber.replace(/\D/g, '');
    // If it starts with '0', replace it with '972'
    if (cleaned.startsWith('0')) {
      cleaned = '972' + cleaned.slice(1);
    }
    return cleaned;
  };

  if (error) return <div>خطأ: {error}</div>;

  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: 3,
      }}
    >
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6}>
          <Typography
            variant="h5"
            align="right"
            sx={{ fontWeight: 600, color: '#333' }}
          >
            إدارة المستخدمين
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            label="بحث عن مستخدم"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#f5f5f5',
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon color="primary" />
                </IconButton>
              ),
            }}
            helperText="أدخل الاسم الأول، اسم العائلة، أو البريد الإلكتروني للبحث"
          />
        </Grid>

        <Grid item xs={12}>
          <Chip
            label={
              loading ? (
                <div
                  style={{
                    position: 'relative',
                    height: '20px',
                    width: '50px',
                  }}
                >
                  <Lottie
                    animationData={fetchAnimation}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      height: '50px',
                      width: '50px',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              ) : (
                `إجمالي المستخدمين: ${filteredUsers.length}`
              )
            }
            color="primary"
            sx={{
              p: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'auto',
              overflow: 'visible',
              mb: 2,
            }}
          />
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Table aria-label="users table">
              <TableHead>
                <StyledTableRow>
                  {columnHeaders.map((column) => (
                    <StyledTableCell
                      align="center"
                      key={column.key}
                      width={column.width}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      colSpan={columnHeaders.length}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={gear}
                          alt="Loading..."
                          style={{ marginRight: '10px' }}
                        />
                        جار التحميل...
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : filteredUsers.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      colSpan={columnHeaders.length}
                    >
                      لا تتوافر بيانات
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  displayedUsers.map((user) => (
                    <StyledTableRow
                      key={user.email}
                      sx={{
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      }}
                    >
                      {columnHeaders.map((column) => (
                        <StyledTableCell key={column.key} align="center">
                          {column.key === 'actions' ? (
                            <UserActions user={user} fetchUsers={fetchUsers} />
                          ) : column.key === 'phoneNumber' ? (
                            <a
                              href={`https://wa.me/${formatPhoneNumber(
                                user.phoneNumber
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {user.phoneNumber}
                            </a>
                          ) : (
                            user[column.key]
                          )}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(filteredUsers.length / rowsPerPage)}
            page={currentPage}
            onChange={(event, newPage) => setCurrentPage(newPage)}
            shape="rounded"
            variant="outlined"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default UsersManagement;
