import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Divider,
  Tooltip,
  IconButton,
  Grid,
  Stack,
  Button,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import { motion } from 'framer-motion';
import EditUser from './EditUser';
import { updateUserDetails } from '../../Services/UserService';

const roleTranslations = {
  USER: 'مستخدم عادي',
  FREEMIUM: 'اشتراك مجاني',
  ADMIN: 'مدير',
  BRONZE: 'اشتراك برونزي',
  SILVER: 'اشتراك فضي',
  GOLD: 'اشتراك ذهبي ',
  EXPERIMENTAL: 'اشتراك تجريبي',
};

const planColors = {
  USER: '',
  ADMIN: '#FF4136',
  GOLD: '#cfac39',
  SILVER: '#A8ABAE',
  BRONZE: '#C5832B',
  FREEMIUM: '#FF851B',
  EXPERIMENTAL: '#FF851B',
};

const UserProfile = ({ userInfo, studyConsistency, renderProgress }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setEditDialogOpen(false);
  };

  const handleUpdateDetails = (userId, userDetails) => {
    return updateUserDetails(userId, userDetails);
  };

  return (
    <Paper
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 2,
        position: 'relative',
        margin: { xs: 0, md: 2 },
        height: '36.5rem',
        '& .top-left-corner': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '30px',
          height: '30px',
          borderBottomRightRadius: '100%',
          backgroundColor: planColors[userInfo.role],
        },
        '& .top-right-corner': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: '30px',
          height: '30px',
          borderBottomLeftRadius: '100%',
          backgroundColor: planColors[userInfo.role],
        },
        '& .bottom-left-corner': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '30px',
          height: '30px',
          borderTopRightRadius: '100%',
          backgroundColor: planColors[userInfo.role],
        },
        '& .bottom-right-corner': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '30px',
          height: '30px',
          borderTopLeftRadius: '100%',
          backgroundColor: planColors[userInfo.role],
        },
        border: '3px solid ' + planColors[userInfo.role],
      }}
    >
      <div className="top-left-corner"></div>
      <div className="top-right-corner"></div>
      <div className="bottom-left-corner"></div>
      <div className="bottom-right-corner"></div>
      <Box sx={{ width: '100%' }}>
        <img
          src="/assets/yael-logo.png"
          alt="Default Logo"
          style={{
            width: '60%',
            height: '100px',
            objectFit: 'contain',
            margin: 'auto',
            display: 'block',
          }}
        />
      </Box>
      <Grid container spacing={2} direction="column" alignItems="center">
        {/* User Name */}
        <Grid item xs={12}>
          <Typography
            variant="h6"
            noWrap
            color="textSecondary"
            align="center"
            fontWeight="bold"
          >
            {userInfo && `${userInfo.firstName} ${userInfo.lastName}`}
          </Typography>
        </Grid>

        {/* User Phone and Email */}
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={{ xs: 1, sm: 3 }}
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
            useFlexGap
            flexWrap="wrap"
          >
            <Box textAlign="center" sx={{ flex: '1' }}>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                fontWeight="bold"
              >
                {userInfo && userInfo.phoneNumber}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                رقم الهاتف
              </Typography>
            </Box>

            <Box textAlign="center" sx={{ flex: '1' }}>
              <Tooltip
                title={userInfo && userInfo.email ? userInfo.email : ''}
                placement="top"
              >
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  fontWeight="bold"
                  noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {userInfo && userInfo.email.length > 23
                    ? `...${userInfo.email.substring(0, 20)}`
                    : userInfo.email}
                </Typography>
              </Tooltip>
              <Typography variant="caption" color="textSecondary">
                عنوان البريد الإلكتروني
              </Typography>
            </Box>
          </Stack>
        </Grid>

        {/* User Role */}
        <Grid
          item
          xs={12}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="subtitle1"
            align="center"
            fontWeight="bold"
            sx={{
              color: planColors[userInfo.role],
            }}
          >
            {userInfo && roleTranslations[userInfo.role]}
          </Typography>

          <Typography variant="caption" align="center" color="textSecondary">
            نوع الاشتراك
          </Typography>
        </Grid>

        {/* User Details Update Button */}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={handleOpenDialog}
            sx={{
              borderColor: '#ff7e20',
              color: '#2a3c4e',
            }}
          >
            تغيير التفاصيل
          </Button>
        </Grid>

        {/* Progress Monitoring */}
        <Box width="100%" mt={3}>
          <Divider variant="middle" />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={4}
          >
            <motion.div whileHover={{ scale: 1.1 }}>
              <InsightsOutlinedIcon
                sx={{ marginRight: 1, color: 'textSecondary' }}
              />
            </motion.div>

            <Typography
              variant="h6"
              noWrap
              color="textSecondary"
              align="center"
            >
              استمرارية الامتحانات
            </Typography>

            <Tooltip title="يعرض هذا الشريط استمرارية أدائك في الامتحانات خلال الشهر. حافظ على حل الامتحانات يومياً لزيادة نسبتك!">
              <IconButton size="small">
                <InfoOutlinedIcon sx={{ color: 'black', cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </Box>

          <Box mt={2}>{renderProgress(studyConsistency + 3 || 0)}</Box>

          <Box mt={2} textAlign="center">
            <Typography variant="subtitle1">
              {studyConsistency
                ? studyConsistency >= 7
                  ? 'لقد قمت بإجراء الامتحانات بانتظام كل يوم هذا الأسبوع. استمر في هذا الأداء الرائع!'
                  : `لقد قمت بإجراء الامتحانات بانتظام في ${studyConsistency} من أيام هذا الأسبوع. حافظ على انتظامك!`
                : 'لم تقم بإجراء أي امتحانات حتى الآن هذا الأسبوع. ابدأ اليوم!'}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <EditUser
        open={editDialogOpen}
        onClose={handleCloseDialog}
        userInfo={userInfo}
        onUpdate={handleUpdateDetails}
      />
    </Paper>
  );
};

export default UserProfile;
