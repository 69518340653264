import React, { useEffect, useState } from 'react';
import {
  getLastCompositionByUserId,
  submitComposition,
} from '../../Services/CompositionService';
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  TextField,
} from '@mui/material';
import { useAuth } from '../../Contexts/AuthContext';
import CompositionTimer from './CompositionTimer';
import { getQuestionById } from '../../Services/QuestionService';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Utilities/Colors';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Lottie from 'lottie-react';
import hourglass from '../../loaders/hourglass.json';
import loader from '../../loaders/loader.svg';

const CompositionWriting = () => {
  const { user } = useAuth();
  const [composition, setComposition] = useState(null);
  const [question, setQuestion] = useState(null);
  const [textContent, setTextContent] = useState('');
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLastComposition = async () => {
      try {
        const lastComposition = await getLastCompositionByUserId(user.id);
        setComposition(lastComposition);

        if (lastComposition && lastComposition.submitted) {
          navigate('/practice/selection');
          return;
        }
      } catch (error) {
        console.error('خطأ في جلب الإنشاء الأخير:', error);
      }
      setLoading(false);
    };

    fetchLastComposition();
  }, [user.id, navigate]);

  useEffect(() => {
    const fetchQuestion = async () => {
      if (composition && composition.questionId) {
        try {
          const fetchedQuestion = await getQuestionById(composition.questionId);
          setQuestion(fetchedQuestion);
        } catch (error) {
          console.error('خطأ في جلب السؤال:', error);
        }
      }
    };

    fetchQuestion();
  }, [composition]);

  const handleSubmit = async () => {
    const loadingAlert = MySwal.fire({
      title: 'يتم تسليم الإنشاء',
      html: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Lottie
            animationData={hourglass}
            style={{
              height: '100px',
              width: '100px',
            }}
          />
        </div>
      ),
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
    });

    try {
      await submitComposition(textContent, user.id);

      loadingAlert.close();

      Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      }).fire({
        icon: 'success',
        title: 'تم تسليم الإنشاء بنجاح',
      });

      navigate('/practice/selection');
    } catch (error) {
      loadingAlert.close();

      console.error('خطأ في تحميل الإنشاء:', error);

      Swal.fire({
        icon: 'error',
        title: 'خطأ',
        text: 'خطأ في تحميل الإنشاء',
      });
    }
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 4,
        borderRadius: 3,
        mt: 3,
        boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: loading ? '' : Colors.platinum,
      }}
    >
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <img src={loader} alt="Loading..." />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              mt: 1,
            }}
          >
            يتم إحضار المعلومات...
          </Typography>
        </Box>
      ) : (
        <>
          {composition && (
            <>
              <Grid item xs={12} sx={{ mb: 3 }}>
                <CompositionTimer
                  startTime={composition.compositionStartDate}
                  endTime={composition.compositionEndDate}
                  onTimeUp={handleSubmit}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  p: 3,
                  backgroundColor: Colors.inputColor,
                  borderRadius: 3,
                  border: `1px solid ${Colors.lightBlue}`,
                  mb: 3,
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  gutterBottom
                  sx={{
                    color: Colors.darkBlue,
                    borderBottom: `2px solid ${Colors.lightBlue}`,
                    pb: 1,
                    mb: 2,
                  }}
                >
                  سؤال الإنشاء
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    color: Colors.independence,
                    lineHeight: 1.6,
                  }}
                >
                  {question && question.text}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={12} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              multiline
              minRows={10}
              variant="outlined"
              placeholder="اكتب الإنشاء هنا..."
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{
                mb: 3,
                maxWidth: '250px',
                borderRadius: '8px',
                backgroundColor: Colors.primary,
                ':hover': {
                  backgroundColor: Colors.primaryDark,
                },
                color: Colors.textOnPrimary,
                fontFamily: 'ExpoArabic',
              }}
            >
              إرسال الإنشاء
            </Button>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default CompositionWriting;
