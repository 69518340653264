import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Drawer, List, ListItem, Divider } from '@mui/material';
import Lottie from 'lottie-react';
import hourglassAnimation from '../../loaders/hourglass.json';

const drawerVariants = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
};

const listItemVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: index * 0.1,
    },
  }),
};

const linkStyle = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'block',
  textAlign: 'center',
};
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 10000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const MobileNavBar = ({
  isOpen,
  toggleDrawer,
  isLoggingOut,
  isAuthenticated,
  isAdmin,
  logout,
}) => {
  return (
    <>
      {isLoggingOut && (
        <div style={overlayStyle}>
          <Lottie animationData={hourglassAnimation} />
          <p
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              marginTop: '20px',
            }}
          >
            جارٍ تسجيل الخروج، يرجى الانتظار...
          </p>
        </div>
      )}

      <Drawer
        anchor="top"
        open={isOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2, overflow: 'hidden' },
        }}
      >
        <List
          component={motion.div}
          initial="hidden"
          animate="visible"
          variants={drawerVariants}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {[
            ['التمرين', '/practice/selection'],
            ['معلومات ', '/information'],
            ['المساحة الشخصية', '/me'],
            // ['المتجر', '/subscriptions/plans'],
            ['تواصل', '/contact'],
          ].map(([text, link], index) => (
            <Link to={link} key={index} style={linkStyle}>
              <motion.div variants={listItemVariants} custom={index}>
                <ListItem
                  onClick={toggleDrawer(false)}
                  sx={{
                    fontSize: '1.1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    fontFamily: 'ExpoArabic',
                  }}
                >
                  {text}
                </ListItem>
              </motion.div>
              <Divider
                sx={{ my: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
              />
            </Link>
          ))}
          {isAdmin && (
            <Link to="/admin/dashboard" style={linkStyle}>
              <motion.div variants={listItemVariants} custom={4}>
                <ListItem
                  onClick={toggleDrawer(false)}
                  sx={{
                    fontSize: '1.1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    fontFamily: 'ExpoArabic',
                  }}
                >
                  لوحة الإدارة
                </ListItem>
              </motion.div>
              <Divider
                sx={{ my: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
              />
            </Link>
          )}
          {isAuthenticated ? (
            <motion.div variants={listItemVariants} custom={isAdmin ? 5 : 4}>
              <ListItem
                onClick={() => {
                  logout();
                  toggleDrawer(false);
                }}
                sx={{
                  fontSize: '1.1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  fontFamily: 'ExpoArabic',
                }}
              >
                تسجيل الخروج
              </ListItem>
            </motion.div>
          ) : (
            <Link to="/auth/login" style={linkStyle}>
              <motion.div variants={listItemVariants} custom={isAdmin ? 6 : 5}>
                <ListItem
                  onClick={toggleDrawer(false)}
                  sx={{
                    fontSize: '1.1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    fontFamily: 'ExpoArabic',
                  }}
                >
                  تسجيل الدخول
                </ListItem>
              </motion.div>
            </Link>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default MobileNavBar;
