import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Container,
  Box,
} from '@mui/material';
import { Colors } from '../../../Utilities/Colors';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const PlanForm = ({ plan, onChange, allPerks }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="اسم الخطة"
            name="name"
            value={plan.name}
            onChange={onChange}
            fullWidth
            margin="dense"
            sx={{
              backgroundColor: Colors.inputColor,
              borderRadius: '8px',
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel sx={{ color: Colors.darkBlue }}>
              الدور المرتبط
            </InputLabel>
            <Select
              name="role"
              value={plan.role}
              onChange={onChange}
              sx={{
                '& .MuiSelect-select': { backgroundColor: Colors.inputColor },
              }}
            >
              <MenuItem value="FREEMIUM">مجاني</MenuItem>
              <MenuItem value="EXPERIMENTAL">تجريبي</MenuItem>
              <MenuItem value="BRONZE">برونزي</MenuItem>
              <MenuItem value="SILVER">فضي</MenuItem>
              <MenuItem value="GOLD">ذهبي</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="المدة (بالأيام)"
            name="duration"
            type="number"
            value={plan.duration}
            onChange={onChange}
            fullWidth
            margin="dense"
            sx={{
              backgroundColor: Colors.inputColor,
              borderRadius: '8px',
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel sx={{ color: Colors.darkBlue }}>المزايا</InputLabel>
            <Select
              multiple
              name="perkIds"
              value={plan.perkIds}
              onChange={onChange}
              sx={{
                '& .MuiSelect-select': {
                  backgroundColor: Colors.inputColor,
                },
              }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '250px',
                    overflow: 'auto',
                  },
                },
              }}
            >
              {allPerks.map((perk) => (
                <MenuItem key={perk.id} value={perk.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <span>{perk.name}</span>
                    {plan.perkIds.includes(perk.id) ? (
                      <CheckBoxOutlinedIcon
                        sx={{ marginLeft: 1, color: Colors.darkBlue }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon
                        sx={{ marginLeft: 1, color: Colors.darkBlue }}
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="السعر"
            name="price"
            type="number"
            value={plan.price}
            onChange={onChange}
            fullWidth
            margin="dense"
            sx={{
              backgroundColor: Colors.inputColor,
              borderRadius: '8px',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PlanForm;
